import * as Swal from "sweetalert2";

window.Main = new (function () {

    var $windowWidth = $(window).width();
    var that = this;

    //Mobile functions
    let destructor = function () {
        $('.navSite').detach().appendTo('.DskNavBox');
        $('body').removeClass('noScroll');
    }

    let constructor = function () {
        $('.navSite').detach().prependTo('.sdNav');
    }

    this.matchesMenu = function () {
        let breackpoint = 1023,
            mql = window.matchMedia("(max-width:" + breackpoint + "px)");

        mediaqueryresponse(mql)

        mql.addListener(mediaqueryresponse)

        function mediaqueryresponse(mql) {
            if (mql.matches) {
                constructor();
            } else {
                destructor();
            }
        }
    }

    //open-close menu
    this.menuToggle = function () {
        $('body').toggleClass('noScroll');
    }

    /**
     * Init
     */
    this.init = function () {

        //mobile menu
        that.matchesMenu();
        $('.hamburger').on('click', that.menuToggle);
        that.headerClass();

        $('.goTo').on('click', that.scrollToElement);

    };

    /**
     * add class to header on scroll
     */
    this.headerClass = function () {
        $(window).scroll(function () {
            if ($(window).scrollTop() > 15) {
                $('header').addClass('stick')
            } else {
                $('header').removeClass('stick')
            }
            if ($('body').hasClass('is_team')) {
                $('header').addClass('stick');
            }
        })
    }

    /**
     * Home listener
     */
    this.home = function () {

        try {
            $('.blog-slider').slick({
                dots: true,
                arrows: false,
                slidesToShow: 1,
                infinite: false,
                responsive: [
                    {
                        breakpoint: 1920,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        } catch (e) {
            console.log(e);
        }

        try {
            $('.us-slider').slick({
                dots: false,
                arrows: false,
                slidesToShow: 1,
                infinite: true,
                autoplay: true,
                fade: true,
                cssEase: 'ease-in-out',
            });
        } catch (e) {
            console.log(e);
        }

        try {
            $('.instagram-slider').slick({
                dots: false,
                arrows: true,
                prevArrow: $('.slider-prev'),
                nextArrow: $('.slider-next'),
                slidesToShow: 3,
                infinite: false,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            infinite: true,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                    // You can unslick at a given breakpoint now by adding:
                    // settings: "unslick"
                    // instead of a settings object
                ]
            });
        } catch (e) {
            console.log(e);
        }
        try {
            $('.slick-drone').slick({
                dots: false,
                arrows: false,
                slidesToShow: 1,
                infinite: true,
                autoplay: true,
                fade: true,
                cssEase: 'ease-in-out',
                speed: 100
            });
        } catch (e) {
            console.log(e);
        }

    };

    /**
     * Scroll to element
     */
    this.scrollToElement = function () {
        let goToElement = $(this).data('place');

        $('.sd-navLeft li a').removeClass('active');
        $(this).addClass('active');

        $('.hamburger').trigger('click');

        $('html, body').animate({
            scrollTop: $("#" + goToElement).offset().top
        }, 1000);
    };

    /**
     * Move drone
     */
    this.moveDrone = function () {
        setTimeout(function (){
            $('#drone').addClass('animate');
        }, 100);
    };

    /**
     * Return drone
     */
    this.returnDrone = function () {
        setTimeout(function (){
            $('#drone').removeClass('animate');
        }, 100);
    };

})();

$(window).resize(function () {
    var $windowWidth = $(window).width();
    if ($windowWidth > 991) {
        //Main.unSlick();
    } else {
        //Main.init();
    }
});

$(window).scroll(function () {

    try {
        var element = document.getElementById('flux');
        var scrollPosition = $(window).scrollTop();

        if (scrollPosition >= (element.scrollHeight))
        {
            Main.moveDrone();
        } else {
            Main.returnDrone();
        }
    } catch (e) {

    }

});
